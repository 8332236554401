





























































































































































@import "~@/assets/styles/helpers/variables";
.loader {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  background: white;
}
.card {
  &-title {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 0;
  }
  &-sub {
    font-weight: normal;
    font-size: 12px;
    color: #9797ad !important;
  }
  &-t {
    color: #9797ad !important;
    &-title {
      color:#9797ad;
      font-size: 16px;
      font-weight: bold;
    }
    &-type {
      height: 25px;
    }
    span {
      color: #525252;
      font-size: 14px;
    }
  }
  &-links {
    display: block;
    width: 100%;
    background: #efefef;
    padding: 15px 0;
    font-size: 14px;
    &-text{
      color: #5076ff;
      span {
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          color: #708efc;
        }
      }
      span.ex:hover{
          color: red !important;
        
      }
    }
  }
}

